// BG Images
export const BG_COVER = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/1%20Cover%20Depan_oR79ouS2J.jpg?updatedAt=1703438922660`;
export const BG_COUNTING_DOWN = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/4%20Counting%20Days_62DokLV0J.jpg?updatedAt=1703438938811`;
export const BG_INSTAGRAM_FILTER = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/COVER%20RANDY%20KAROLIN_dIegkADdj.jpg?updatedAt=1703503021348`;
export const BG_GUEST_INFO = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/7%20wish_ZoS_wgJOt.jpg?updatedAt=1703438946747`;
export const BG_CONFIRMATION = BG_GUEST_INFO;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/3%20bridging%203_XyPe0OQhp.jpg?updatedAt=1703438938461`;

// Welcoming Image
export const IMG_PHOTO_1 = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/3%20bridging%201_TU0y7G763.jpg?updatedAt=1703480742445`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/3%20bridging%202_thQHOHyVE.jpg?updatedAt=1703438944779`;
export const IMG_PHOTO_3 = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/3%20bridging%203_XyPe0OQhp.jpg?updatedAt=1703438938461`;

// Wedding Detail Image
export const IMG_AKAD = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/5%20akad_M5LNdU2z-.jpg?updatedAt=1703438929632`;
export const IMG_RECEPTION = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/5%20resepsi_5gzWc8ZFL.jpg?updatedAt=1703438932178`;
export const IMG_DRESSCODE = `https://user-images.githubusercontent.com/10141928/169198604-51a5748c-b5ca-4a7b-8576-d9bf6945e0d6.jpg`;

// Couple Image
export const IMG_COUPLE = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/2%20Brides_JgH8GIJXD5.jpg?updatedAt=1703438929629`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/168402483-62cc3dd6-b5ba-4939-ac3d-cc136bd9bdbe.jpg`;
export const IMG_MAN = `https://user-images.githubusercontent.com/10141928/159520655-4e91aa6f-ba20-4f74-912a-62a0f361fc75.jpg`;
export const IMG_GIRL = `https://user-images.githubusercontent.com/10141928/159520628-f6c02869-e08e-4720-a4bd-095799e79cdf.jpg`;
export const IMG_WELCOMING_SECTION = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/2%20welcome_vHbmaYQaO.jpg?updatedAt=1703438929743`;
export const IMG_WISHES = BG_GUEST_INFO;

// Cover Gallery Image
export const IMG_GALLERY_1 = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/6%20gal%201_eO8C3wJRN.jpg?updatedAt=1703438946949`;
export const IMG_GALLERY_2 = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/6%20gal%202_-hLdzjDAe.jpg?updatedAt=1703438945286`;
export const IMG_GALLERY_COVER = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/VOLT3815_5yCF6s0qB.jpg?updatedAt=1703440317401`;

// Logo Section
export const IMG_LOGO = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/9_GaT9YAg1S.png?updatedAt=1703868464340`;
export const IMG_LOGO_INVERT = `https://ik.imagekit.io/masansgaming/Randy%20Karolins/8_tHWu5xOi3.png?updatedAt=1703868464302`;

/**
 * Image Flower Assets
 * @important - IF YOU WANT TO OVERRIDE THIS ASSETS,
 * MAKE SURE YOU REPLACE WITH SAME RESOLUTION
 */
export const ASSETS_FLOWER_LEFT = `https://user-images.githubusercontent.com/10141928/167748673-62d2c7c6-9064-49f2-bcc0-9be00aaf510a.png`;

/**
 * Invitato Image Assets
 * @important - DON'T CHANGE THIS CODE
 */
export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};
